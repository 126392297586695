import axios from "axios";
import { endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";
import { initialState } from "../../utils/reduxSlice/userSlice";

const userDetailApi = async (prospectAppId) => {
  try {
    const userObj = { ...initialState };
    const apiLink = `${endpoint}/api/user/${prospectAppId}`;
    const response = await axios.get(apiLink);
    const data = response.data;
    if (data) {
      userObj.birthDate = data.data.birth_date;
      userObj.channelId = data.data.channel_id;
      userObj.email = data.data.email;
      userObj.emailVerified = data.data.email_verified;
      userObj.firstName = data.data.first_name;
      userObj.lastName = data.data.last_name;
      userObj.isEditing = data.data.is_editing;
      userObj.merchantId = data.data.merchant_location_id;
      userObj.phoneCode = data.data.mobile_phone_code;
      userObj.phoneNumber = data.data.mobile_phone_number;
      userObj.roleId = data.data.role_id;
      userObj.userId = data.data.user_id;
      userObj.pan = data.data.tin;
      userObj.languageId = data.data.language;
      userObj.bl_completed = data.data.bl_completed || 0;
      userObj.pl_completed = data.data.pl_completed || 0;
      userObj.employment_status_code = data.data.employment_status_code || "";
      userObj.business_registration_type = data.data.business_registration_type || "";
      userObj.address1 = data.data.address1 || ""
      userObj.address2 = data.data.address2 || ""
      userObj.postal_code = data.data.postal_code || "";
      userObj.gender = data.data.gender || "";
      userObj.inflight_credit_app_id = data.data.inflight_credit_app_id || "";
      userObj.pl_update_required = data.data.pl_update_required;
      userObj.pl_se_update_required = data.data.pl_se_update_required;
      userObj.residence_type = data.data.residence_type || "";
      userObj.desired_loan_amount = data.data.se_loan_amount || "";
      userObj.allow_sms = data.data.allow_sms || 0
      userObj.allow_email = data.data.allow_emails || 0
      userObj.business_current_turnover = data.data.business_current_turnover || ""
      userObj.business_years = data.data.business_years || ""
      userObj.employer_name = data.data.employer_name || ""
      userObj.officepincode = data.data.officepincode || ""
      userObj.monthly_income = data.data.monthly_income || ""
      userObj.salary_mode = data.data.salary_mode || ""
      userObj.monthly_rent = data.data.monthly_rent || 0
      userObj.educational_level = data.data.educational_level || ""
      userObj.emi = data.data.emi || ""
      userObj.prefer_net_banking = data.data.prefer_net_banking || ""
    }

    return userObj;
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  }
};

export default userDetailApi;

import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Fade, Box, Modal, Backdrop, TextField, Dialog, useMediaQuery, Slide, DialogContent, DialogActions, Grid, IconButton, Checkbox } from '@mui/material';
import axios from 'axios';
import { forwardRef, useEffect, useState } from 'react';
import { app_title, endpoint } from '../../config';
import styled from '@emotion/styled';
import { customToast } from '../../lib/toastLib';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import CloseIcon from "@mui/icons-material/Close";
import { Helmet } from 'react-helmet-async';
import CreditCardUpdateDialog from '../../components/admin/CreditCardUpdateDialog';
import { EditContent } from './CreditCards';

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function Lenders() {
	const { t } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [editName, setEditName] = useState(null);
	const [editRecord, setEditRecord] = useState({});

	// Fetch lender list
	const [lenders, setLenders] = useState([]);
	useEffect(() => {
		fetchLendersList()
	}, [])

	const fetchLendersList = () => {
		axios.get(`${endpoint}/api/get-lenders-data`)
			.then(({ data }) => {
				setLenders(data)
			})
			.catch((error) => {
				if (error.response) {
					customToast.error(error.response.data?.message);
				} else {
					customToast.error(error.message);
				}
			})
	}

	// Create Lender
	const [showLenderModal, setShowLenderModal] = useState(false);
	const [lenderName, setLenderName] = useState("")
	const [createLenderLoading, setCreateLenderLoading] = useState(false)

	const handleInputChange = (e, index) => {
		const name = e.target.name;
		let value = e.target.value;

		if (["salaried", "self_employed", "business", "lender_status"].includes(name)) {
			value = e.target.checked ? 1 : 0;
		}

		if (name === 'priority') {
			if (isNaN(Number(value)) || Number(value) < 0) return;
		}

		const updateLenders = lenders.map((lender, i) => {
			if (i === index) {
				return {
					...lender,
					[name]: value
				}
			}
			return lender;
		});

		setLenders(updateLenders);
	}

	const createLender = (e) => {
		e.preventDefault();

		setCreateLenderLoading(true)
		axios.post(`${endpoint}/api/create-lender`, {
			name: lenderName
		}).then(() => {
			setLenderName("")
			fetchLendersList()
			setShowLenderModal(false)
			customToast.success("Lender created successfully!");
		}).catch((error) => {
			if (error.response) {
				customToast.error(error.response.data?.message);
			} else {
				customToast.error(error.message);
			}
		}).finally(() => {
			setCreateLenderLoading(false)
		})
	}

	// Update the Lender field
	const [editedIndex, setEditedIndex] = useState({
		row: -1,
		column: ""
	});

	const updateTheField = (e, id) => {
		const name = e.target.name;
		let value = e.target.value;

		if (["salaried", "self_employed", "business", "lender_status"].includes(name)) {
			value = e.target.checked ? 1 : 0;
		}

		if (["apr_min", "apr_max"].includes(name)) {
			value = parseFloat(value);
		}

		axios.post(`${endpoint}/api/update-lender-data`, {
			lender_id: id,
			property: name,
			value: value
		}).then(() => {
			customToast.success("Lender updated successfully!");
		}).catch((error) => {
			if (error.response) {
				customToast.error(error.response.data?.message);
			} else {
				customToast.error(error.message);
			}
		}).finally(() => {
			setEditedIndex({
				row: -1,
				column: ''
			})
		})
	}

	// Delete Lender
	const [deleteId, setDeleteId] = useState(-1);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const deleteLender = () => {
		setDeleteLoading(true)
		axios.post(`${endpoint}/api/safe-delete-lender`, {
			lender_id: deleteId
		}).then(() => {
			customToast.success("Lender deleted successfully!");
			setLenders((prev) => {
				return prev.filter(item => item.lender_id !== deleteId)
			})
			setDeleteId(-1);
		}).catch((error) => {
			if (error.response) {
				customToast.error(error.response.data?.message);
			} else {
				customToast.error(error.message);
			}
		}).finally(() => {
			setDeleteLoading(false)
		})
	}

	const handleEdit = (name, record) => {
		setEditName(name)
		setEditRecord({
			id: record.lender_id,
			...record
		})
	}

	return <>
		<Helmet>
			<title>Admin - Lenders {app_title && `| ${app_title}`}</title>
		</Helmet>
		<Paper variant="outlined" sx={{ padding: 2 }}>
			<Box sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center'
			}}>
				<Typography variant="h6" color="secondary.main" mb={2}>Lenders</Typography>
				<Button
					variant='contained'
					onClick={() => {
						setShowLenderModal(true)
						setLenderName("")
					}}
				>
					Add Lender
				</Button>
			</Box>

			{/* begin::Table Container */}
			<TableContainer component={Paper}>
				<Table loading={true} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Revenue Share (%)</TableCell>
							<TableCell>Revenue Share Flate Fee (₹)</TableCell>
							<TableCell>
								<span style={{ whiteSpace: 'nowrap' }}>Income</span> <br />
								<small>(min)</small>
							</TableCell>
							<TableCell>
								<span style={{ whiteSpace: 'nowrap' }}>Age</span> <br />
								<small>(min)</small>
							</TableCell>
							<TableCell>
								<span style={{ whiteSpace: 'nowrap' }}>Age</span> <br />
								<small>(max)</small>
							</TableCell>
							<TableCell>
								<span style={{ whiteSpace: 'nowrap' }}>Credit Score</span> <br />
								<small>(min)</small>
							</TableCell>
							<TableCell>
								<span style={{ whiteSpace: 'nowrap' }}>Credit Score</span> <br />
								<small>(max)</small>
							</TableCell>
							<TableCell><span style={{ whiteSpace: 'nowrap' }}>Salaried</span></TableCell>
							<TableCell><span style={{ whiteSpace: 'nowrap' }}>Self Employed</span></TableCell>
							<TableCell><span style={{ whiteSpace: 'nowrap' }}>Loan Amount upto</span></TableCell>
							<TableCell>
								<span style={{ whiteSpace: 'nowrap' }}>Tenure</span><br />
								<small>(months)</small>
							</TableCell>
							<TableCell><span style={{ whiteSpace: 'nowrap' }}>Interest Rate</span></TableCell>
							<TableCell><span style={{ whiteSpace: 'nowrap' }}>Monthly PR</span><br />
								<small>(min)</small>
							</TableCell>
							<TableCell><span style={{ whiteSpace: 'nowrap' }}>Monthly PR</span><br />
								<small>(max)</small>
							</TableCell>
							<TableCell>
								<span style={{ whiteSpace: 'nowrap' }}>Processing Fees</span><br />
								<small style={{ whiteSpace: 'nowrap' }}>(% of loan amount)</small>
							</TableCell>
							<TableCell><span style={{ whiteSpace: 'nowrap' }}>Business</span></TableCell>
							<TableCell><span style={{ whiteSpace: 'nowrap' }}>Priority</span></TableCell>
							<TableCell><span style={{ whiteSpace: 'nowrap' }}>Lender Status</span></TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{lenders.map((lender, idx) => (
							<TableRow>
								<TableCell>
									{lender.name}
								</TableCell>
								<TableCell align="center" component="th" scope="row" sx={{
									position: 'relative',
									maxWidth: '200px',
									'&:hover .hover-box': {
										display: 'flex',
									},
								}}>
									{lender.revenue_percent.toFixed(2) ?? 0}%
									<EditContent onClick={() => handleEdit('revenue_percent', lender)} />
								</TableCell>
								<TableCell align="center" component="th" scope="row" sx={{
									position: 'relative',
									maxWidth: '200px',
									'&:hover .hover-box': {
										display: 'flex',
									},
								}}>
									{lender.revenue_flat_fee ?? 0}
									<EditContent onClick={() => handleEdit('revenue_flat_fee', lender)} />
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'min_income' ?
										<TextField value={lenders[idx].min_income} name="min_income" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'min_income'
											})
										}}>
											{lender.min_income}
										</Button>
									}
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'min_age' ?
										<TextField value={lenders[idx].min_age} name="min_age" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'min_age'
											})
										}}>
											{lender.min_age}
										</Button>
									}
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'max_age' ?
										<TextField value={lenders[idx].max_age} name="max_age" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'max_age'
											})
										}}>
											{lender.max_age}
										</Button>
									}
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'min_credit_score' ?
										<TextField value={lenders[idx].min_credit_score} name="min_credit_score" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'min_credit_score'
											})
										}}>
											{lender.min_credit_score}
										</Button>
									}
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'max_credit_score' ?
										<TextField value={lenders[idx].max_credit_score} name="max_credit_score" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'max_credit_score'
											})
										}}>
											{lender.max_credit_score}
										</Button>
									}
								</TableCell>
								<TableCell>
									{/* <TextField value={lenders[idx].salaried} name="salaried" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} /> */}
									<Checkbox
										name="salaried"
										checked={lenders[idx].salaried === 1}
										onChange={(e) => {
											handleInputChange(e, idx)
											updateTheField(e, lender.lender_id)
										}}
									/>
								</TableCell>
								<TableCell>
									<Checkbox
										name="self_employed"
										checked={lenders[idx].self_employed === 1}
										onChange={(e) => {
											handleInputChange(e, idx)
											updateTheField(e, lender.lender_id)
										}}
									/>
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'loan_amount_upto' ?
										<TextField value={lenders[idx].loan_amount_upto} name="loan_amount_upto" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'loan_amount_upto'
											})
										}}>
											{lender.loan_amount_upto}
										</Button>
									}
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'tenure' ?
										<TextField value={lenders[idx].tenure} name="tenure" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />

										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'tenure'
											})
										}}>
											{lender.tenure}
										</Button>
									}
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'interest_rate' ?
										<TextField value={lenders[idx].interest_rate} name="interest_rate" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'interest_rate'
											})
										}}>
											{lender.interest_rate}
										</Button>
									}
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'apr_min' ?
										<TextField value={lenders[idx].apr_min} name="apr_min" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'apr_min'
											})
										}}>
											{lender.apr_min}
										</Button>
									}
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'apr_max' ?
										<TextField value={lenders[idx].apr_max} name="apr_max" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'apr_max'
											})
										}}>
											{lender.apr_max}
										</Button>
									}
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'processing_fees' ?
										<TextField value={lenders[idx].processing_fees} name="processing_fees" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'processing_fees'
											})
										}}>
											{lender.processing_fees}
										</Button>
									}
								</TableCell>
								<TableCell>
									<Checkbox
										name="business"
										checked={lenders[idx].business === 1}
										onChange={(e) => {
											handleInputChange(e, idx)
											updateTheField(e, lender.lender_id)
										}}
									/>
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'priority' ?
										<TextField value={lenders[idx].priority} name="priority" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'priority'
											})
										}}>
											{lender.priority}
										</Button>
									}
								</TableCell>
								<TableCell>
									<Checkbox
										name="lender_status"
										checked={lenders[idx].lender_status === 1}
										onChange={(e) => {
											handleInputChange(e, idx)
											updateTheField(e, lender.lender_id)
										}}
									/>
								</TableCell>
								<TableCell>
									<Button
										color="error"
										variant="contained"
										size="small"
										onClick={() => setDeleteId(lender.lender_id)}
									>
										Delete
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{/* end::Table Container */}

			{/* begin::Delete confirmation popup */}
			<StyledModal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={deleteId !== -1}
				onClose={() => setDeleteId(-1)}
				closeAfterTransition
				slots={{ backdrop: StyledBackdrop }}
			>
				<Fade in={deleteId !== -1}>
					<Box sx={style}>
						<Typography align='center' variant="h6" color="secondary.main" mb={2}>Are you sure?</Typography>
						<Box sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							gap: 2,
						}}>
							<Button
								color="primary"
								variant="outlined"
								size="small"
								onClick={() => setDeleteId(-1)}
							>
								Cancel
							</Button>
							<LoadingButton
								loading={deleteLoading}
								color="error"
								variant="contained"
								size="small"
								onClick={deleteLender}
							>
								Delete
							</LoadingButton>
						</Box>
					</Box>
				</Fade>
			</StyledModal>
			{/* end::Delete confirmation popup */}

			{/* begin::Create lender modal */}
			<Dialog
				open={showLenderModal}
				scroll="paper"
				fullScreen={fullScreen}
				fullWidth
				TransitionComponent={Transition}
				component="form"
				autoComplete="off"
				onSubmit={createLender}
			>
				<DialogContent>
					<Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
						<IconButton onClick={() => setShowLenderModal(false)}>
							<CloseIcon />
						</IconButton>
					</Grid>
					<Grid container justifyContent="center" spacing={3}>
						<Grid item xs={12}>
							<TextField
								margin="dense"
								required
								fullWidth
								label={t("Name")}
								name="lenderName"
								value={lenderName}
								onChange={(e) => setLenderName(e.target.value)}
							>
							</TextField>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<LoadingButton
						loading={createLenderLoading}
						color="secondary"
						variant="contained"
						size="large"
						fullWidth
						type="submit"
					>
						{t("Submit")}
					</LoadingButton>
				</DialogActions>
			</Dialog>
			{/* end::Create lender modal */}
		</Paper>

		<CreditCardUpdateDialog
			open={!!editName}
			name={editName}
			type="number"
			record={editRecord}
			onClose={() => {
				setEditRecord({})
				setEditName(null)
			}}
			onSave={() => {
				fetchLendersList()
				setEditRecord({})
				setEditName(null)
			}}
			module={"lenders"}
		/>
	</>
}

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	borderRadius: '12px',
	padding: '16px 32px 24px 32px',
	backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
	boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
});
import { partnersList } from "../config/partners";
import { ipAddressURL } from "./../config";

// validate if the date is valid/in range of caledar
export const isValidDate = (year, month, date) => {
  const builtDate = new Date(year, month, date);
  return (
    builtDate.getFullYear() === year &&
    builtDate.getMonth() === month &&
    builtDate.getDate() === date
  );
};

// calculate age from date
export const calculateAge = (dob) => {
  const diff_ms = Date.now() - dob.getTime();
  const age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getFullYear() - 1970);
};

// mask email
export const maskEmail = (str) => {
  const mask = (part) => {
    const strLen = part.length;
    if (strLen > 2) {
      return (
        part.substr(0, 1) +
        part.substr(2, strLen - 1).replace(/\w/g, "*") +
        part.substr(-1, 1)
      );
    }
    return part.replace(/\w/g, "*");
  };
  return str.replace(
    /([\w.]+)@([\w-.]+)(\.[\w-.]+)/g,
    (_, p1, p2, p3) => mask(p1) + "@" + mask(p2) + p3
  );
};

// mask phone number
export const maskPhoneNumber = (str) => {
  return `******${str.substring(str.length - 4)}`;
};

// convert s(seconds) to mm:ss(minute:seconds)
export const secondsToMinuteSeconds = (e) => {
  const m = Math.floor((e % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const s = Math.floor(e % 60)
    .toString()
    .padStart(2, "0");

  return m + ":" + s;
};

//returns dd
const dd_form = (originalDate) => {
  return `${originalDate.getDate() < 10
    ? `0${originalDate.getDate()}`
    : originalDate.getDate()
    }`;
};

//returns mm
const mm_form = (originalDate) => {
  return `${originalDate.getMonth() + 1 < 10
    ? `0${originalDate.getMonth() + 1}`
    : originalDate.getMonth() + 1
    }`;
};

//returns yyyy
const yyyy_form = (originalDate) => {
  return `${originalDate.getFullYear()}`;
};

//date to yyyymmdd format
export const dateToyyyymmdd = (originalDate, seperator) => {
  const convertedDate = `${yyyy_form(originalDate)}${seperator}${mm_form(
    originalDate
  )}${seperator}${dd_form(originalDate)}`;
  return convertedDate;
};

//yyyymmdd string to date, month, year
export const yyymmddStrSplit = (str, seperator) => {
  const splittedArr = str ? str.split(seperator) : [];
  const date = Number(splittedArr[2]) || 1;
  const month = Number(splittedArr[1]) || 1;
  const year = Number(splittedArr[0]) || 2000;
  return { date, month, year };
};

// save JWT token in cookie
export const saveJWTTokenInCookie = (token) => {
  document.cookie = `atkn=${token}; path=/; SameSite=None; Secure`;
};

// remove JWT token from cookie
export const removeJWTTokenFromCookie = () => {
  document.cookie = `atkn=; path=/; max-age=${0}`;
};

// Get JWT token from cookie
export const getJWTTokenFromCookie = () => {
  const cookieToken = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("atkn="));
  const token = cookieToken ? `Bearer ${cookieToken.substring(5)}` : undefined;
  return token;
};

// save Partner ID in Cookie
export const savePartnerIdCookie = (partnerId) => {
  document.cookie = `partnerId=${partnerId}; path=/; SameSite=None; Secure`;
};

// remove Partner ID in Cookie
export const removePartnerIdCookie = () => {
  document.cookie = `partnerId=; path=/; max-age=${0}`;
};

// Get Partner ID from cookie
export const getPartnerIdFromCookie = () => {
  const cookiePartnerId = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("partnerId="));
  const partnerID = cookiePartnerId ? cookiePartnerId.substring(10) : undefined;
  return partnerID === "null" ? null : Number(partnerID);
};

// save lead in Cookie
export const saveLeadCookie = (lead) => {
  document.cookie = `lead=${lead}; path=/; SameSite=None; Secure`;
};

// remove lead in Cookie
export const removeLeadCookie = () => {
  document.cookie = `lead=; path=/; max-age=${0}`;
};

// Get lead from cookie
export const getLeadFromCookie = () => {
  const cookieRoleId = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("lead="));
  const lead = cookieRoleId ? cookieRoleId.substring(5) : undefined;

  return lead === "null" ? null : lead;
};

// save Partner ID in Cookie
export const saveRoleIdCookie = (roleId) => {
  document.cookie = `roleId=${encrypt(roleId)}; path=/; SameSite=None; Secure`;
};

// remove Partner ID in Cookie
export const removeRoleIdCookie = () => {
  document.cookie = `roleId=; path=/; max-age=${0}`;
};

// Get Partner ID from cookie
export const getRoleIdFromCookie = () => {
  const cookieRoleId = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("roleId="));
  const roleId = cookieRoleId ? cookieRoleId.substring(7) : undefined;

  return roleId === "null" ? null : Number(decrypt(roleId));
};

export const saveMerchantIdCookie = (merchantId) => {
  document.cookie = `merchantId=${merchantId}; path=/; SameSite=None; Secure`;
};

// remove App_id from cookie
export const removeMerchantIdFromCookie = () => {
  document.cookie = `merchantId=; path=/; max-age=${0}`;
};

// Get App_id from cookie
export const getMerchantIdFromCookie = () => {
  const cookieMerchantId = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("merchantId="));
  const merchantID = cookieMerchantId
    ? cookieMerchantId.substring(11)
    : undefined;
  return merchantID;
};

// save qr in cookie
export const saveQrinCookie = (qr) => {
  document.cookie = `qr=${qr}; path=/; SameSite=None; Secure`;
};

// remove qr from cookie
export const removeQrFromCookie = () => {
  document.cookie = `qr=; path=/; max-age=${0}`;
};

// Get qr from cookie
export const getQrFromCookie = () => {
  const cookieQr = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("qr="));
  const qr_code = cookieQr ? cookieQr.substring(3) : undefined;
  return qr_code;
};

// save url in cookie
export const saveUrlinCookie = (url) => {
  document.cookie = `url=${url}; path=/; SameSite=None; Secure`;
};

// remove url from cookie
export const removeUrlFromCookie = () => {
  document.cookie = `url=; path=/; max-age=${0}`;
};

// Get url from cookie
export const getUrlFromCookie = () => {
  const cookieUrl = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("url="));
  const url_link = cookieUrl ? cookieUrl.substring(4) : undefined;
  return url_link;
};

// save App_id in cookie
export const saveAppIdInCookie = (app_id) => {
  document.cookie = `appId=${app_id}; path=/; SameSite=None; Secure`;
};

// remove App_id from cookie
export const removeAppIdFromCookie = () => {
  document.cookie = `appId=; path=/; max-age=${0}`;
};

// Get App_id from cookie
export const getAppIdFromCookie = () => {
  const cookieAppId = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("appId="));
  const app_Id = cookieAppId ? cookieAppId.substring(6) : undefined;
  return app_Id;
};

// save prospectAppId in cookie
export const saveProspectAppIdInCookie = (prospect_app_id) => {
  document.cookie = `prospectAppId=${prospect_app_id}; path=/; SameSite=None; Secure`;
};

// remove prospectAppId from cookie
export const removeProspectAppIdFromCookie = () => {
  document.cookie = `prospectAppId=; path=/; max-age=${0}`;
};

// Get prospectAppId from cookie
export const getProspectAppIdFromCookie = () => {
  const cookieProspectAppId = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("prospectAppId="));
  const prospectAppId = cookieProspectAppId
    ? cookieProspectAppId.substring(14)
    : undefined;
  return prospectAppId;
};

// save urlSegment in cookie
export const saveUrlSegmentInCookie = (urlSegment) => {
  document.cookie = `urlSegment=${urlSegment}; path=/; SameSite=None; Secure`;
};

// remove urlSegment from cookie
export const removeUrlSegmentFromCookie = () => {
  document.cookie = `urlSegment=; path=/; max-age=${0}`;
};

// Get urlSegment from cookie
export const getUrlSegmentFromCookie = () => {
  const cookieUrlSegment = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("urlSegment="));
  const urlSegment = cookieUrlSegment
    ? cookieUrlSegment.substring(11)
    : undefined;
  return urlSegment;
};

// save productType in cookie
export const saveProductTypeInCookie = (productType) => {
  document.cookie = `productType=${productType}; path=/; SameSite=None; Secure`;
};

// remove productType from cookie
export const removeProductTypeFromCookie = () => {
  document.cookie = `productType=; path=/; max-age=${0}`;
};

// Get productType from cookie
export const getProductTypeFromCookie = () => {
  const cookieProductType = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("productType="));
  const productType = cookieProductType
    ? cookieProductType.substring(12)
    : undefined;
  return productType;
};

// save productType in cookie
export const saveCreditCardDetailsInCookie = (details) => {
  document.cookie = `CCDetails=${JSON.stringify(details)}; path=/; SameSite=None; Secure`;
};

// remove productType from cookie
export const removeCreditCardDetailsFromCookie = () => {
  document.cookie = `CCDetails=; path=/; max-age=${0}`;
};

// Get productType from cookie
export const getCreditCardDetailsFromCookie = () => {
  const cookieCCDetails = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("CCDetails="));
  const CCDetails = cookieCCDetails
    ? JSON.parse(cookieCCDetails.substring(10))
    : [];
  return CCDetails;
};


// save productId in cookie
export const saveProductIdInCookie = (productId) => {
  document.cookie = `productId=${productId}; path=/; SameSite=None; Secure`;
};

// remove productId from cookie
export const removeProductIdFromCookie = () => {
  document.cookie = `productId=; path=/; max-age=${0}`;
};

// Get productId from cookie
export const getProductIdFromCookie = () => {
  const cookieProductId = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("productId="));
  const productId = cookieProductId
    ? cookieProductId.substring(10)
    : undefined;
  return productId;
};

// save UTMs in cookie
export const saveUTMsCookie = (utms) => {
  document.cookie = `utms=${JSON.stringify(utms)}; path=/; SameSite=None; Secure`;
};

// remove UTMs from cookie
export const removeUTMsFromCookie = () => {
  document.cookie = `utms=; path=/; max-age=${0}`;
};

// Get UTMs from cookie
export const getUTMsFromCookie = () => {
  const cookieUTMs = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("utms="));
  const utms = cookieUTMs
    ? JSON.parse(cookieUTMs.substring(5))
    : {};
  return utms;
};


// clear cookie data saved by app
export const clearCookieData = () => {
  removeProspectAppIdFromCookie(); // removing prospectAppId from cookie
  removeJWTTokenFromCookie(); // removing token from cookie
  removeAppIdFromCookie(); // removing app_id from cookie
  removeUrlSegmentFromCookie(); // removing urlSegment from cookie
  removeProductTypeFromCookie(); // removing productType from cookie
  removeProductIdFromCookie(); // removing productId from cookie
  removeUTMsFromCookie(); // removing UTMs from cookie
  removeCreditCardDetailsFromCookie(); // removing Credit card details from cookie
};

// save Admin JWT token in cookie
export const saveAdminJWTTokenInCookie = (token) => {
  document.cookie = `a_atkn=${token}; path=/; SameSite=None; Secure`;
};

// remove Admin JWT token from cookie
export const removeAdminJWTTokenFromCookie = () => {
  document.cookie = `a_atkn=; path=/; max-age=${0}`;
};

// Get Admin JWT token from cookie
export const getAdminJWTTokenFromCookie = () => {
  const cookieToken = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("a_atkn="));
  const token = cookieToken ? `Bearer ${cookieToken.substring(7)}` : undefined;
  return token;
};

// save AdminId in cookie
export const saveAdminIdInCookie = (adminId) => {
  document.cookie = `adminId=${adminId}; path=/; SameSite=None; Secure`;
};

// remove AdminId from cookie
export const removeAdminIdFromCookie = () => {
  document.cookie = `adminId=; path=/; max-age=${0}`;
};

// Get AdminId from cookie
export const getAdminIdFromCookie = () => {
  const cookieAdminId = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("adminId="));
  const adminId = cookieAdminId ? cookieAdminId.substring(8) : undefined;
  return adminId;
};

// save isPartner in cookie
export const saveIsPartnerInCookie = (isPartner) => {
  document.cookie = `isPartner=${isPartner}; path=/; SameSite=None; Secure`;
};

// remove isPartner from cookie
export const removeIsPartnerFromCookie = () => {
  document.cookie = `isPartner=; path=/; max-age=${0}`;
};

// Get AdminId from cookie
export const getIsPartnerFromCookie = () => {
  const cookieIsPartner = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("isPartner="));
  const isPartner = cookieIsPartner ? cookieIsPartner.substring(10) : undefined;
  return isPartner;
};

// clear admin cookie data saved by app
export const clearAdminCookieData = () => {
  removeAdminJWTTokenFromCookie(); // removing admin token from c
  removeRoleIdCookie(); // removing admin role id
  removeAdminIdFromCookie(); // remvoing adminId from cookie
};

// save Admin JWT token in cookie
export const savePartnerJWTTokenInCookie = (token) => {
  document.cookie = `p_atkn=${token}; path=/; SameSite=None; Secure`;
};

// remove Admin JWT token from cookie
export const removePartnerJWTTokenFromCookie = () => {
  document.cookie = `p_atkn=; path=/; max-age=${0}`;
};

// Get Partner JWT token from cookie
export const getPartnerJWTTokenFromCookie = () => {
  const cookieToken = document.cookie
    .split("; ")
    .find((pair) => pair.startsWith("p_atkn="));
  const token = cookieToken ? `Bearer ${cookieToken.substring(7)}` : undefined;
  return token;
};
// clear admin cookie data saved by app
export const clearPartnerCookieData = () => {
  removePartnerJWTTokenFromCookie(); // removing admin token from cookie
  removePartnerIdCookie(); // remvoing adminId from cookie
  removeIsPartnerFromCookie();
};

// get current user geolocation
export const getGeoLocation = () => {
  return new Promise(function (resolve, reject) {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  })
    .then((res) => res.coords)
    .catch(() => -1);
};

// get current browser name
export const getCurrentBrowserName = () => {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) != -1
  ) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Edg") != -1) {
    return "Edge";
  } else if (navigator.userAgent.indexOf("Chrome") != -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") != -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    return "Firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") != -1 ||
    !!document.documentMode == true
  ) {
    //IF IE > 10
    return "IE";
  } else {
    return "unknown";
  }
};

export const applySorting = (records, orderBy, order) => {
  return records.sort((a, b) => {
    const aValue =
      typeof a[orderBy] === "string" ? a[orderBy].toLowerCase() : a[orderBy];
    const bValue =
      typeof b[orderBy] === "string" ? b[orderBy].toLowerCase() : b[orderBy];
    if (
      (order === "desc" && bValue < aValue) ||
      (order === "asc" && bValue > aValue)
    ) {
      return -1;
    }
    if (
      (order === "asc" && bValue > aValue) ||
      (order === "desc" && bValue < aValue)
    ) {
      return 1;
    }
    return 0;
  });
};

// download blob file
export const downloadBlobFile = (blob, type, fileName = "download") => {
  const blobObj = new Blob([blob], {
    type,
  });
  const link = document.createElement("a");
  link._target = "_blank";
  link.href = window.URL.createObjectURL(blobObj);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// sleep execution for give "ms"
export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// check user route
export const isUserRoute = () => {
  const paths = window.location.pathname.split("/");
  return paths[1] === "user";
};

// check admin route
export const isAdminRoute = () => {
  const paths = window.location.pathname.split("/");
  return paths[1] === "admin";
};

// check admin route
export const isPartnerRoute = () => {
  const paths = window.location.pathname.split("/");
  return paths[1] === "partner";
};

// get current user token
export const getToken = () => {
  let token = null;
  if (isAdminRoute()) {
    token = getAdminJWTTokenFromCookie();
  }
  if (isUserRoute()) {
    token = getJWTTokenFromCookie();
  }
  return token;
};

// get current user ip address
export const getIpAddress = async () => {
  let ip = null;
  await fetch(ipAddressURL)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      ip = data.ip;
    })
    .catch((error) => {
      console.log(error);
    });
  return ip;
};

export const isPartnerAvailable = () => {
  const partner_name = process.env.REACT_APP_PARTNER_API_NAME;
  return partnersList.includes(partner_name);
};

export const pixelTrackingEvent = (trackingPayload) => {
  if (process.env.REACT_APP_ENV === "PROD") {
    let pixelScript = document.createElement("script");
    pixelScript.innerHTML = `getProductData(${JSON.stringify(
      trackingPayload
    )})`;
    document.body.appendChild(pixelScript);
  }
};

export const getDate = (daysOff = 0) => {
  const today = new Date();

  const daysOffDate = new Date(today);
  daysOffDate.setDate(today.getDate() - daysOff);

  const formattedDate = daysOffDate.toLocaleDateString('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });

  return formattedDate
}

export const priorDaysList = [
  {
    label: "Yesterday",
    startDate: new Date(
      new Date().setDate(new Date().getDate() - 1)
    ).toLocaleDateString("fr-CA"),
    endDate: new Date(
      new Date().setDate(new Date().getDate() - 1)
    ).toLocaleDateString("fr-CA"),
    value: "yesterday",
  },
  {
    label: "Last 7 Days",
    startDate: new Date(
      new Date().setDate(new Date().getDate() - 7)
    ).toLocaleDateString("fr-CA"),
    endDate: new Date(
      new Date().setDate(new Date().getDate() - 1)
    ).toLocaleDateString("fr-CA"),
    value: "last_7_days",
  },
  {
    label: "Last 30 Days",
    startDate: new Date(
      new Date().setDate(new Date().getDate() - 30)
    ).toLocaleDateString("fr-CA"),
    endDate: new Date(
      new Date().setDate(new Date().getDate() - 1)
    ).toLocaleDateString("fr-CA"),
    value: "last_30_days",
  },
  {
    label: "Custom Range",
    startDate: "",
    endDate: "",
    value: "custom_range",
  },
];

export const numberFormat = (number) => {
  let nf = new Intl.NumberFormat("hi-IN", {
    style: "currency",
    currency: "INR",
  });
  return nf.format(number);
};

export const encrypt = (key) => {
  return btoa(key);
}

export const decrypt = (key) => {
  return atob(key);
}

export const flattenArray = (arr, key) => {
  const result = [];

  arr.forEach(item => {
    result.push(item);

    if (item[key]) {
      result.push(...item[key]);
    }
  });

  return result;
}

export const isPrimaryDomain = !process.env.REACT_APP_PRODUCT_TYPE && !process.env.REACT_APP_SUBDOMAIN
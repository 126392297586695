import { createSlice } from "@reduxjs/toolkit";
import { phoneCode } from "../../config";
import { yyymmddStrSplit } from "..";

const initialState = {
  birthDate: "",
  channelId: "",
  email: "",
  emailVerified: undefined,
  firstName: "",
  lastName: "",
  isEditing: undefined,
  merchantId: "",
  phoneCode: phoneCode,
  phoneNumber: "",
  roleId: undefined,
  userId: undefined,
  pan: "",
  dob_date: "",
  dob_month: "",
  dob_year: "",
  languageId: undefined,
  bl_completed: undefined,
  pl_completed: undefined,
  employment_status_code: "",
  business_registration_type: "",
  postal_code: "",
  address1: "",
  address2: "",
  gender: "",
  pl_update_required: 0,
  pl_se_update_required: 0,
  residence_type: "",
  desired_loan_amount: "",
  allow_email: 0,
  allow_sms: 0,
  ip_address: '',
  mac_address: '',
  business_current_turnover: "",
  business_years: "",
  employer_name: "",
  officepincode: "",
  monthly_income: "",
  salary_mode: "",
  monthly_rent: 0,
  educational_level: "",
  emi: 0,
  prefer_net_banking: null
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserDetail: (state, action) => {
      state.birthDate = action.payload.birthDate || "";
      state.channelId = action.payload.channelId || "";
      state.email = action.payload.email || "";
      state.emailVerified = action.payload.emailVerified;
      state.firstName = action.payload.firstName || "";
      state.lastName = action.payload.lastName || "";
      state.isEditing = action.payload.isEditing;
      state.merchantId = action.payload.merchantId || "";
      state.phoneCode = action.payload.phoneCode;
      state.phoneNumber = action.payload.phoneNumber || "";
      state.roleId = action.payload.roleId;
      state.userId = action.payload.userId;
      state.pan = action.payload.pan;
      state.dob_date = action.payload.birthDate ? yyymmddStrSplit(action.payload.birthDate, "-").date : "";
      state.dob_month = action.payload.birthDate ? yyymmddStrSplit(action.payload.birthDate, "-").month : "";
      state.dob_year = action.payload.birthDate ? yyymmddStrSplit(action.payload.birthDate, "-").year : "";
      state.languageId = action.payload.languageId;
      state.bl_completed = action.payload.bl_completed;
      state.pl_completed = action.payload.pl_completed;
      state.employment_status_code = action.payload.employment_status_code || ''
      state.business_registration_type = action.payload.business_registration_type || ""
      state.address1 = action.payload.address1 || ""
      state.address2 = action.payload.address2 || ""
      state.postal_code = action.payload.postal_code || ""
      state.gender = action.payload.gender || ""
      state.pl_update_required = action.payload.pl_update_required
      state.pl_se_update_required = action.payload.pl_se_update_required
      state.residence_type = action.payload.residence_type
      state.desired_loan_amount = action.payload.desired_loan_amount || 0
      state.allow_sms = action.payload.allow_sms
      state.allow_email = action.payload.allow_email
      state.business_current_turnover = action.payload.business_current_turnover || ""
      state.business_years = action.payload.business_years || ""
      state.employer_name = action.payload.employer_name || ""
      state.officepincode = action.payload.officepincode || ""
      state.monthly_income = action.payload.monthly_income || ""
      state.salary_mode = action.payload.salary_mode || ""
      state.monthly_rent = action.payload.monthly_rent || 0
      state.educational_level = action.payload.educational_level || 0
      state.emi = action.payload.emi || 0
      state.prefer_net_banking = action.payload.prefer_net_banking || null
    },
    updateIsEditing: (state, action) => {
      state.isEditing = action.payload;
    },
    updatePlRequired: (state, action) => {
      state.pl_update_required = action.payload;
    },
    updatePlSeRequired: (state, action) => {
      state.pl_se_update_required = action.payload;
    },
    updateIPMACInfo: (state, action) => {
      state.ip_address = action.payload.ip_address;
      state.mac_address = action.payload.mac_address;
    }
  },
});

// actions export
export const { updateUserDetail, updateIsEditing, updatePlRequired, updatePlSeRequired, updateIPMACInfo } = userSlice.actions;

// initial state export
export { initialState };

export default userSlice.reducer;
